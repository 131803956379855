import React, { useState, useEffect } from "react";
import PageContainer from "../components/PageContainer";
import { Button, TextField, FormControl } from "@mui/material";
import { createTheme } from "@mui/material/styles";

import { createGrader } from "../api";
import AlertSnackbar from "../components/AlertSnackbar";
import useClasses from "../hooks/useClasses";

const theme = createTheme();

const styles = () => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

function AddGrader() {
  const classes = useClasses(styles);
  const [alertState, setAlertState] = useState(null);
  const [grader, setGrader] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (!grader || !grader.username || !grader.password) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [grader]);

  const submitForm = () => {
    createGrader(grader)
      .then((res) => {
        setAlertState("success");
      })
      .catch((err) => {
        setAlertState("error");
      });
  };

  const handleChange = (e, elem) => {
    setGrader({ ...grader, [e.target.id]: e.target.value });
  };

  return (
    <PageContainer>
      <h1>Add New Grader</h1>
      <div style={{ bottom: 0, position: "relative" }}>
        <FormControl className={classes.formControl}>
          <TextField
            id="username"
            label="Username"
            type="text"
            defaultValue=""
            onChange={handleChange}
            autoComplete="off"
            variant="standard"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="password"
            label="Password"
            type="password"
            defaultValue=""
            onChange={handleChange}
            autoComplete="off"
            variant="standard"
          />
        </FormControl>
        {/* <div> */}
        <Button
          disabled={!isFormValid}
          onClick={submitForm}
          variant="contained"
          color="primary"
          style={{ bottom: 7, position: "absolute", marginLeft: 10 }}
        >
          Ajouter
        </Button>
      </div>
      {/* </div> */}
      <AlertSnackbar
        state={alertState}
        handleClose={() => setAlertState(null)}
      />
    </PageContainer>
  );
}

export default AddGrader;
