import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";

import { getSubmissionsStats } from "../api";
import PageContainer from "../components/PageContainer";

function Stats() {
  // const [submissionStats, setSubmissionStats] = useState(null);
  const [evaluationsStats, setEvaluationsStats] = useState(null);

  useEffect(() => {
    getSubmissionsStats().then((res) => {
      setEvaluationsStats(res.data.evaluations);
    });
  }, []);

  return (
    <PageContainer>
      {!evaluationsStats ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <h1>Stats (last 7 days)</h1>
          <BarChart width={1500} height={500} data={evaluationsStats}>
            <CartesianGrid />
            <XAxis dataKey="name" />
            <YAxis />
            <Bar
              dataKey="count.total"
              stackId="a"
              fill="#3f51b5"
              label={{ fill: "white", fontSize: 20 }}
            />
            {/* <Bar dataKey="count.pending" stackId="a" fill="#82ca9d" /> */}
          </BarChart>
        </>
      )}
    </PageContainer>
  );
}

export default Stats;
