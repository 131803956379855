import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { CircularProgress, Button, Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { getSubmission, deleteSubmission } from "../api";
import PageContainer from "../components/PageContainer";

function Submission() {
  const history = useHistory();
  const [submission, setSubmission] = useState(null);
  let { submissionId } = useParams();

  useEffect(() => {
    getSubmission(submissionId).then((res) => {
      setSubmission(res.data);
    });
  }, [submissionId]);

  const onDeleteSubmissionClick = () => {
    deleteSubmission(submissionId).then((res) => {
      history.push("/submissions");
    });
  };

  return (
    <PageContainer>
      {!submission ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <h1>Submission Details</h1>
          <p>
            <b>id : </b>
            {submission.submission_id}
          </p>
          <p>
            <b>Creation : </b>
            {submission.created_at}
          </p>
          <p>
            <b>User : </b>
            <Link to={`/user/${submission.user.user_id}`}>
              {submission.user.name}
            </Link>
          </p>
          <p>
            <b>Evaluation : </b>
            {submission.evaluation.name}
          </p>
          <p>
            <b>Status : </b>
            {submission.status}
          </p>
          <p>
            <b>Grader : </b>
            {!!submission.grader ? submission.grader.username : "null"}
          </p>
          <b>Files :</b>
          <ul>
            {submission.files.map((file) => (
              <li key={file.filename}>
                <a href={file.presigned_url} target="_blank" rel="noreferrer">
                  {file.filename}
                </a>
              </li>
            ))}
          </ul>
          <p>
            <b>Message : </b>
            {submission.message ? (
              <div style={{ marginTop: 10 }}>
                <textarea
                  rows="4"
                  cols="40"
                  readOnly
                  value={submission.message}
                />
              </div>
            ) : (
              "null"
            )}
          </p>

          <h2>Related Submissions :</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 300 }}>id</TableCell>
                  <TableCell align="right">Timestamp</TableCell>
                  <TableCell align="right">User</TableCell>
                  <TableCell align="right">Evaluation</TableCell>
                  <TableCell align="right">File(s)</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Grader</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submission.related?.map((submission) => (
                  <TableRow key={submission.submission_id}>
                    <TableCell>
                      <Link to={`/submission/${submission.submission_id}`}>
                        {submission.submission_id}
                      </Link>
                    </TableCell>

                    <TableCell align="right">{submission.created_at}</TableCell>
                    <TableCell align="right">
                      <Link to={`/user/${submission.user.user_id}`}>
                        {submission.user.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      {submission.evaluation.name}
                    </TableCell>
                    <TableCell align="right">
                      {submission.files.map((file, idx) => (
                        <div key={idx}>
                          <a href={file.presigned_url}>{file.filename}</a>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell align="right">{submission.status}</TableCell>
                    <TableCell align="right">
                      {!!submission.grader
                        ? submission.grader.username
                        : "null"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {["pending", "in_correction"].includes(submission.status) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "700",
                }}
              >
                <Button
                  onClick={onDeleteSubmissionClick}
                  color="error"
                  variant="contained"
                  style={{ marginTop: "0" }}
                >
                  Supprimer
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}
    </PageContainer>
  );
}

export default Submission;
