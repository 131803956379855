import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Select,
  MenuItem,
  Dialog,
  Button,
  TextareaAutosize,
} from "@mui/material";

import { getGraders, getSubmissions, updateSubmission } from "../api";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const InlineButton = styled("span")({
  cursor: "pointer",
  textDecoration: "underline",
  padding: "5px",
  "&:hover": {
    color: "#3f51b5",
  },
});

function Submissions() {
  const history = useHistory();
  const [submissions, setSubmissions] = useState(null);
  const [graders, setGraders] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [statusUpdateInfo, setStatusUpdateInfo] = useState({
    dialogOpen: false,
  });

  useEffect(() => {
    getSubmissions(["pending", "in_correction"], 0, 50).then((res) => {
      setSubmissions(res.data.submissions);
    });
    getGraders().then((res) => {
      setGraders(res.data);
    });
  }, [refreshKey, history]);

  const updateGrader = (submissionId, graderId, status) => {
    const data = {
      grader_id: graderId,
    };

    if (status === "pending") {
      data.status = "in_correction";
    }
    updateSubmission(submissionId, data).then((res) => {
      setRefreshKey((oldKey) => oldKey + 1);
    });
  };

  const openUpdateStatusDialog = (submission, status) => {
    setStatusUpdateInfo({
      dialogOpen: true,
      info: {
        submission,
        newStatus: status,
      },
    });
  };

  const updateStatus = (info, message) => {
    updateSubmission(info.submission.submission_id, {
      status: info.newStatus,
      message,
    }).finally(() => {
      setRefreshKey((oldKey) => oldKey + 1);
      setStatusUpdateInfo({
        dialogOpen: false,
      });
    });
  };

  return (
    <PageContainer>
      <h1>Submissions</h1>
      {!submissions ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table
            // className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 300 }}>id</TableCell>
                  <TableCell align="right">Timestamp</TableCell>
                  <TableCell align="right">User</TableCell>
                  <TableCell align="right">Evaluation</TableCell>
                  <TableCell align="right">File(s)</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Grader</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submissions.map((submission) => (
                  <TableRow key={submission.submission_id}>
                    <TableCell>
                      <Link to={`/submission/${submission.submission_id}`}>
                        {submission.submission_id}
                      </Link>
                    </TableCell>

                    <TableCell align="right">{submission.created_at}</TableCell>
                    <TableCell align="right">
                      <Link to={`/user/${submission.user.user_id}`}>
                        {submission.user.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      {submission.evaluation.name}
                    </TableCell>
                    <TableCell align="right">
                      {submission.files.map((file, idx) => (
                        <div key={idx}>
                          <a href={file.presigned_url}>{file.filename}</a>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell align="right">{submission.status}</TableCell>
                    <TableCell align="right">
                      <Select
                        labelId="workspace-select"
                        id="workspace_id"
                        onChange={(e) =>
                          updateGrader(
                            submission.submission_id,
                            e.target.value,
                            submission.status
                          )
                        }
                        value={
                          !!submission.grader ? submission.grader.grader_id : ""
                        }
                      >
                        {graders.map((grader) => (
                          <MenuItem
                            value={grader.grader_id}
                            key={grader.grader_id}
                          >
                            {grader.username}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell align="right">
                      {(submission.status === "pending" ||
                        submission.status === "in_correction") && (
                        <>
                          <InlineButton
                            onClick={() => {
                              if (!submission.grader) {
                                return alert(
                                  "Please select a grader before changing submission's status"
                                );
                              }
                              openUpdateStatusDialog(submission, "passed");
                            }}
                          >
                            Pass
                          </InlineButton>
                          <InlineButton
                            onClick={() => {
                              if (!submission.grader) {
                                return alert(
                                  "Please select a grader before changing submission's status"
                                );
                              }
                              openUpdateStatusDialog(submission, "retake");
                            }}
                          >
                            Reinvite
                          </InlineButton>
                          <InlineButton
                            onClick={() => {
                              if (!submission.grader) {
                                return alert(
                                  "Please select a grader before changing submission's status"
                                );
                              }
                              openUpdateStatusDialog(submission, "failed");
                            }}
                          >
                            Fail
                          </InlineButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p style={{ textAlign: "right" }}>
            <span style={{ marginRight: 10 }}>
              View <Link to="/submissions/past">past submissions</Link>.
            </span>
            <span>
              Add <Link to="/submission/new">new submission</Link>.
            </span>
          </p>
        </>
      )}
      <UpdateStatusDialog
        open={statusUpdateInfo.dialogOpen}
        info={statusUpdateInfo.info}
        onClose={() => setStatusUpdateInfo({ dialogOpen: false })}
        onSubmit={updateStatus}
      />
    </PageContainer>
  );
}

export default Submissions;

function UpdateStatusDialog(props) {
  const { open, onClose, info, onSubmit } = props;
  const [message, setMessage] = useState(null);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(info, message);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {open && (
        <div style={{ minWidth: "500px", padding: "20px" }}>
          <h2>Change status to : {info.newStatus}</h2>
          {info.newStatus === "passed" && (
            <div>
              <p>
                N'oubliez pas de délivrer la certification au user depuis{" "}
                <a
                  href={`https://beta.admin.datascientest.com/train/user/${info.submission.user.user_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  la plateforme admin.
                </a>
              </p>
            </div>
          )}

          {(info.newStatus === "retake" || info.newStatus === "failed") && (
            <div>
              <p>Indiquez les raisons :</p>

              <TextareaAutosize
                minRows={6}
                onChange={(e) => setMessage(e.target.value)}
                style={{ width: "100%" }}
              />

              <p>
                Vous pouvez mettre en forme votre message en utilisant la{" "}
                <a
                  href="https://api.slack.com/reference/surfaces/formatting"
                  target="_blank"
                  rel="noreferrer"
                >
                  notation de Slack
                </a>
                .
              </p>
            </div>
          )}

          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={info.newStatus !== "passed" && !message}
            color="primary"
            style={{ float: "right", marginTop: "20px" }}
          >
            Submit
          </Button>
        </div>
      )}
    </Dialog>
  );
}
