import React, { useState, useEffect } from "react";
import PageContainer from "../components/PageContainer";
import { Button, TextField, FormControl } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { createEvaluation } from "../api";
import AlertSnackbar from "../components/AlertSnackbar";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function AddEvaluation() {
  const classes = useStyles();
  const [evaluation, setEvaluation] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [alertState, setAlertState] = useState(null);

  useEffect(() => {
    if (!evaluation || !evaluation.name) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [evaluation]);

  const submitForm = () => {
    createEvaluation(evaluation)
      .then((res) => {
        setAlertState("success");
      })
      .catch((err) => {
        setAlertState("error");
      });
  };

  const handleChange = (e, elem) => {
    setEvaluation({ ...evaluation, [e.target.id]: e.target.value });
  };

  return (
    <PageContainer>
      <h1>Add New Evaluation</h1>
      <FormControl className={classes.formControl}>
        <TextField
          id="name"
          label="Name"
          type="text"
          defaultValue=""
          onChange={handleChange}
          autoComplete="off"
        />
      </FormControl>
      <div>
        <Button disabled={!isFormValid} onClick={submitForm}>
          Ajouter
        </Button>
      </div>
      <AlertSnackbar
        state={alertState}
        handleClose={() => setAlertState(null)}
      />
    </PageContainer>
  );
}

export default AddEvaluation;
