import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { getUser, getUserSubmissions } from "../api";
import PageContainer from "../components/PageContainer";

function User() {
  const [user, setUser] = useState(null);
  const [userSubmissions, setUserSubmissions] = useState([]);
  let { userId } = useParams();

  useEffect(() => {
    getUser(userId).then((res) => {
      setUser(res.data);
    });

    getUserSubmissions(userId).then((res) => {
      setUserSubmissions(res.data);
    });
  }, [userId]);

  return (
    <PageContainer>
      {!user ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <h1>{user.name}</h1>
          <p>
            <strong>Identifiant : </strong>
            <a
              href={`https://beta.admin.datascientest.com/train/user/${user.user_id}`}
              target="_blank"
              rel="noreferrer"
            >
              {user.user_id}
            </a>
          </p>
          <p>
            <strong>Workspace : </strong>
            {user.workspace.name}
          </p>
          <h2 style={{ marginTop: 40 }}>User's Submissions :</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 300 }}>id</TableCell>
                  <TableCell align="right">Timestamp</TableCell>
                  <TableCell align="right">Evaluation</TableCell>
                  <TableCell align="right">File(s)</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Grader</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userSubmissions.map((submission) => (
                  <TableRow key={submission.submission_id}>
                    <TableCell>
                      <Link to={`/submission/${submission.submission_id}`}>
                        {submission.submission_id}
                      </Link>
                    </TableCell>

                    <TableCell align="right">{submission.created_at}</TableCell>
                    <TableCell align="right">
                      {submission.evaluation.name}
                    </TableCell>
                    <TableCell align="right">
                      {submission.files.map((file, idx) => (
                        <div key={idx}>
                          <a href={file.presigned_url}>{file.filename}</a>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell align="right">{submission.status}</TableCell>
                    <TableCell align="right">
                      {!!submission.grader
                        ? submission.grader.username
                        : "null"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </PageContainer>
  );
}

export default User;
