import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const isLoggedIn = location.pathname !== "/login";

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setAnchorEl(null);
    history.push("/login");
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <IconButton color="inherit" component={Link} to="/" size="large">
            <HomeIcon />
          </IconButton>
          <p style={{ marginLeft: 20 }}>DataScientest - DE Eval</p>
        </Box>
        {isLoggedIn && (
          <>
            <IconButton color="inherit" onClick={handleMenuClick} size="large">
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem
                component={Link}
                to={"/submissions"}
                onClick={handleClose}
              >
                Submissions
              </MenuItem>
              <MenuItem
                component={Link}
                to={"/submission/new"}
                onClick={handleClose}
              >
                Add Submission
              </MenuItem>
              <MenuItem component={Link} to={"/users"} onClick={handleClose}>
                Users
              </MenuItem>
              <MenuItem component={Link} to={"/graders"} onClick={handleClose}>
                Graders
              </MenuItem>
              <MenuItem
                component={Link}
                to={"/submissions/stats"}
                onClick={handleClose}
              >
                Stats
              </MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
