import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button, CircularProgress } from "@mui/material";

import PageContainer from "../components/PageContainer";
import AutocompleteSelect from "../components/AutocompleteSelect";
import { getUsers, getEvaluations, createSubmission } from "../api";
import axios from "axios";
import AlertSnackbar from "../components/AlertSnackbar";

function AddSubmission() {
  const [progressState, setProgressState] = useState(null);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [evaluations, setEvaluations] = useState([]);
  const [selectedEvaluation, setSelectedEvaluation] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getEvaluations().then((res) => {
      setEvaluations(res.data);
    });

    getUsers().then((res) => {
      setUsers(res.data);
    });
  }, []);

  useEffect(() => {
    if (!user || !user.user_id) {
      setIsFormValid(false);
    } else if (!selectedEvaluation || !selectedEvaluation.evaluation_id) {
      setIsFormValid(false);
    } else if (!selectedFiles || !selectedFiles.length) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [user, selectedEvaluation, selectedFiles]);

  const onUserChange = (event, newValue) => {
    setUser(newValue);
  };

  const onEvaluationChange = (event, newValue) => {
    setSelectedEvaluation(newValue);
  };

  const onFileChange = (event, newValue) => {
    setSelectedFiles(event.target.files);
  };

  const submitForm = () => {
    setProgressState("in progress");
    createSubmission(
      user.user_id,
      selectedEvaluation.evaluation_id,
      selectedFiles
    )
      .then((res) => {
        const uploadPromises = res.data.files.map((file, idx) => {
          const formData = new FormData();
          for (var key in file.presigned_url.fields) {
            formData.append(key, file.presigned_url.fields[key]);
          }
          formData.append("file", selectedFiles[idx]);

          return axios.post(file.presigned_url.url, formData, {
            headers: { "Access-Control-Allow-Origin": "*" },
          });
        });

        Promise.all(uploadPromises)
          .then(() => setProgressState("success"))
          .catch((err) => {
            setProgressState("error");
          });
      })
      .catch((err) => {
        setProgressState("error");
      });
  };

  return (
    <PageContainer>
      <h1>Nouvelle Soumission</h1>
      <AutocompleteSelect
        value={user}
        options={users}
        onChange={onUserChange}
        onNewValueClicked={() => history.push("/user/new")}
        label="User"
        optionLabelField="name"
      />
      <AutocompleteSelect
        value={selectedEvaluation}
        options={evaluations}
        onChange={onEvaluationChange}
        onNewValueClicked={() => history.push("/evaluation/new")}
        label="Evaluation"
        optionLabelField="name"
      />
      <Button component="label">
        Select Files
        <input type="file" multiple hidden onChange={onFileChange} />
      </Button>
      <ul>
        {Array.from(selectedFiles).map((file, idx) => (
          <li key={idx}>{file.name}</li>
        ))}
      </ul>
      <Button
        disabled={!isFormValid}
        onClick={submitForm}
        variant="contained"
        color="primary"
      >
        Submit
      </Button>
      {progressState === "in progress" && (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
      <AlertSnackbar
        state={progressState}
        handleClose={() => setProgressState(null)}
      />
    </PageContainer>
  );
}

export default AddSubmission;
