import axios from "axios";
import qs from "qs";
import moment from "moment";

const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Do something with request error
    const { status } = error.response;

    if (status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

function authHeaders() {
  const token = localStorage.getItem("token");

  return {
    Authorization: `Basic ${token}`,
  };
}

function getConfig(auth = true) {
  const config = {
    headers: {
      "x-api-key": apiKey,
    },
  };

  if (auth) {
    config.headers = {
      ...config.headers,
      ...authHeaders(),
    };
  }
  return config;
}

// Login

export const login = (loginData) => {
  return axios.post(`${apiUrl}/login`, loginData, getConfig(false));
};

// Users

export const getUsers = () => {
  return axios.get(`${apiUrl}/users`, getConfig());
};

export const getUser = (userId) => {
  return axios.get(`${apiUrl}/users/${userId}`, getConfig());
};

export const getUserSubmissions = (userId) => {
  return axios.get(`${apiUrl}/users/${userId}/submissions`, getConfig());
};

export const createUser = (user) => {
  return axios.post(`${apiUrl}/users`, user, getConfig());
};

// Submissions

export const getSubmissions = (statusList, page = 1, pageSize = 20) => {
  const requestConfig = getConfig();
  if (!!statusList && statusList.length) {
    requestConfig.params = {
      status: statusList,
      page: page,
      page_size: pageSize,
    };
    requestConfig.paramsSerializer = (p) => {
      return qs.stringify(p, { arrayFormat: "comma" });
    };
  }

  return axios.get(`${apiUrl}/submissions`, requestConfig);
};

export const createSubmission = (userId, evaluationId, files) => {
  const data = {
    user_id: userId,
    evaluation_id: evaluationId,
    files: Array.from(files).map((file) => file.name),
  };
  return axios.post(`${apiUrl}/submissions`, data, getConfig());
};

export const updateSubmission = (submissionId, newData) => {
  return axios.put(
    `${apiUrl}/submissions/${submissionId}`,
    newData,
    getConfig()
  );
};

export const getSubmission = (submissionId) => {
  return axios.get(`${apiUrl}/submissions/${submissionId}`, getConfig());
};

export const deleteSubmission = (submissionId) => {
  return axios.delete(`${apiUrl}/submissions/${submissionId}`, getConfig());
};

export const getSubmissionsStats = (start, end) => {
  const requestConfig = getConfig();
  start = start ? start : moment().subtract(7, "days").startOf("day");
  end = end ? end : moment().startOf("day").add(1, "days");
  requestConfig.params = {
    start: start.format("YYYY-MM-DDTHH:mm:ss"),
    end: end.format("YYYY-MM-DDTHH:mm:ss"),
  };
  return axios.get(`${apiUrl}/submissions/stats`, requestConfig);
};

// Evaluations
export const getEvaluations = () => {
  return axios.get(`${apiUrl}/evaluations`, getConfig());
};

export const createEvaluation = (evaluation) => {
  return axios.post(`${apiUrl}/evaluations`, evaluation, getConfig());
};

// Workspaces
export const getWorkspaces = () => {
  return axios.get(`${apiUrl}/workspaces`, getConfig());
};

// Graders

export const getGraders = () => {
  return axios.get(`${apiUrl}/graders`, getConfig());
};

export const createGrader = (grader) => {
  return axios.post(`${apiUrl}/graders`, grader, getConfig());
};
