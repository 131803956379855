import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Navbar from "./components/Navbar";
import Submissions from "./pages/Submissions";
import Submission from "./pages/Submission";
import PastSubmissions from "./pages/PastSubmissions";
import AddSubmission from "./pages/AddSubmission";
import AddEvaluation from "./pages/AddEvaluation";
import Graders from "./pages/Graders";
import Users from "./pages/Users";
import User from "./pages/User";
import AddUser from "./pages/AddUser";
import NotFound from "./pages/NotFound";
import AddGrader from "./pages/AddGrader";
import Login from "./pages/Login";
import Stats from "./pages/Stats";
import PrivateRoute from "./components/PrivateRoute";

import useToken from "./hooks/useToken";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import indigo from "@mui/material/colors/indigo";
import green from "@mui/material/colors/green";

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: green,
  },
  // typography: {
  //   root: {
  //     fontFamily: ['"Roboto"'].join(","),
  //   },
  // },
});

function App() {
  const { setToken } = useToken();

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Navbar />
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/submissions" />}
          />
          <Route path="/login">
            <Login setToken={setToken} />
          </Route>
          <PrivateRoute
            exact
            path="/submission/new"
            component={AddSubmission}
          />
          <PrivateRoute
            path="/submission/:submissionId"
            component={Submission}
          />
          <PrivateRoute exact path="/submissions" component={Submissions} />
          <PrivateRoute
            exact
            path="/submissions/past"
            component={() => <Redirect to="/submissions/past/0" />}
          />
          <PrivateRoute
            path="/submissions/past/:page"
            component={PastSubmissions}
          />
          <PrivateRoute path="/submissions/stats" component={Stats} />
          <PrivateRoute path="/evaluation/new" component={AddEvaluation} />
          <PrivateRoute path="/graders" component={Graders} />
          <PrivateRoute path="/grader/new" component={AddGrader} />
          <PrivateRoute path="/users" component={Users} />
          <PrivateRoute path="/user/new" component={AddUser} />
          <PrivateRoute path="/user/:userId" component={User} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
