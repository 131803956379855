import React from "react";

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertSnackbar({
  state,
  handleClose,
  successMessage = "Success",
  errorMessage = "Error",
  autoHideDuration = 3000,
}) {
  const close = () => {
    handleClose();
  };

  return (
    <>
      <Snackbar
        open={state === "error"}
        autoHideDuration={autoHideDuration}
        onClose={close}
      >
        <div>
          <Alert onClose={close} severity="error">
            {errorMessage}
          </Alert>
        </div>
      </Snackbar>
      <Snackbar
        open={state === "success"}
        autoHideDuration={autoHideDuration}
        onClose={close}
      >
        <div>
          <Alert onClose={close} severity="success">
            {successMessage}
          </Alert>
        </div>
      </Snackbar>
    </>
  );
}

export default AlertSnackbar;
