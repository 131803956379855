import React, { useState, useEffect } from "react";
import PageContainer from "../components/PageContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

import { getSubmissions } from "../api";
import { Link, useParams, useHistory } from "react-router-dom";

function PastSubmissions() {
  const [submissions, setSubmissions] = useState(null);
  const [totalCount, setTotalCount] = useState(-1);
  const history = useHistory();
  const params = useParams();
  const page = parseInt(params.page);
  const rowsPerPage = 20;

  useEffect(() => {
    getSubmissions(["failed", "retake", "passed"], page, rowsPerPage).then(
      (res) => {
        setSubmissions(res.data.submissions);
        setTimeout(() => setTotalCount(res.data.total_count), 0);
      }
    );
  }, [page]);

  const onPageChange = (event, newPage) => {
    setSubmissions(null);
    setTotalCount(-1);
    history.push(`/submissions/past/${newPage}`);
  };

  return (
    <PageContainer>
      <h1>Past Submissions</h1>
      {!submissions ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 300 }}>id</TableCell>
                  <TableCell align="right">Timestamp</TableCell>
                  <TableCell align="right">User</TableCell>
                  <TableCell align="right">Evaluation</TableCell>
                  <TableCell align="right">File(s)</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Grader</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submissions.map((submission) => (
                  <TableRow key={submission.submission_id}>
                    <TableCell>
                      <Link to={`/submission/${submission.submission_id}`}>
                        {submission.submission_id}
                      </Link>
                    </TableCell>

                    <TableCell align="right">{submission.created_at}</TableCell>
                    <TableCell align="right">
                      <Link to={`/user/${submission.user.user_id}`}>
                        {submission.user.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      {submission.evaluation.name}
                    </TableCell>
                    <TableCell align="right">
                      {submission.files.map((file, idx) => (
                        <div key={idx}>
                          <a href={file.presigned_url}>{file.filename}</a>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell align="right">{submission.status}</TableCell>
                    <TableCell align="right">
                      {submission.grader.username}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            rowsPerPage={rowsPerPage}
            page={totalCount === -1 ? 0 : page}
            onPageChange={onPageChange}
            count={totalCount === -1 ? 0 : totalCount}
          />
        </>
      )}
    </PageContainer>
  );
}

export default PastSubmissions;
