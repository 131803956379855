import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getGraders } from "../api";
import PageContainer from "../components/PageContainer";

function Graders() {
  const [graders, setGraders] = useState(null);

  useEffect(() => {
    getGraders().then((res) => {
      setGraders(res.data);
    });
  }, []);
  return (
    <PageContainer>
      <h1>Graders</h1>
      {!graders ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <ul>
          {graders.map((grader) => (
            <li key={grader.grader_id}>{grader.username}</li>
          ))}
          <li>
            <Link to="/grader/new">Add New</Link>
          </li>
        </ul>
      )}
    </PageContainer>
  );
}

export default Graders;
