import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { TextField, Button } from "@mui/material";

import PageContainer from "../components/PageContainer";
import { login } from "../api";

export default function Login({ setToken }) {
  const [formData, setFormData] = useState({});
  const history = useHistory();

  const handleChange = function (e) {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const submit = function (e) {
    e.preventDefault();
    login(formData).then((res) => {
      setToken(res.data.token);
      history.push("/submissions");
    });
  };

  return (
    <PageContainer>
      <form onSubmit={submit}>
        <LoginFormInputContainer>
          <TextField
            required
            id="username"
            label="Username"
            onChange={handleChange}
            variant="standard"
          />
        </LoginFormInputContainer>
        <LoginFormInputContainer>
          <TextField
            required
            id="password"
            label="Password"
            onChange={handleChange}
            type="password"
            variant="standard"
          />
        </LoginFormInputContainer>
        <LoginFormInputContainer margin={30}>
          <Button variant="contained" color="primary" type="submit">
            Login
          </Button>
        </LoginFormInputContainer>
      </form>
    </PageContainer>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

function LoginFormInputContainer(props) {
  const margin = props.margin || 5;
  return (
    <div style={{ marginTop: margin, marginBottom: margin }}>
      {props.children}
    </div>
  );
}
