import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const filter = createFilterOptions();

function AutocompleteSelect({
  onChange,
  onNewValueClicked,
  options,
  value,
  label,
  optionLabelField,
  style = { width: 300, marginTop: 10, marginBottom: 10 },
}) {
  const onValueChange = (event, newValue) => {
    if (!!newValue && newValue.newValue) {
      onNewValueClicked();
    } else {
      onChange(event, newValue);
    }
  };

  return (
    <Autocomplete
      value={value}
      onChange={onValueChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Kept this unused code because we might
        // want later to only display the "Add New" option
        // if the user has typed somthing ?
        // if (params.inputValue !== "") {
        //   filtered.push({
        //     newValue: true,
        //     name: "Add New",
        //   });
        // }
        filtered.push({
          newValue: true,
          [optionLabelField]: "Add New",
        });

        return filtered;
      }}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }

        return !!option[optionLabelField] ? option[optionLabelField] : "";
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(props, option) => {
        if (option.newValue) {
          return (
            <li {...props}>
              <span style={{ textDecoration: "underline" }}>
                {option[optionLabelField]}
              </span>
            </li>
          );
        }

        return <li {...props}>{option[optionLabelField]} </li>;
      }}
      style={style}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
    />
  );
}

export default AutocompleteSelect;
