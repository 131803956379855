import React, { useState, useEffect } from "react";
import PageContainer from "../components/PageContainer";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";

import { createUser, getWorkspaces } from "../api";
import AlertSnackbar from "../components/AlertSnackbar";
import useClasses from "../hooks/useClasses";

const theme = createTheme();

const styles = () => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

function AddUser() {
  const classes = useClasses(styles);
  const [user, setUser] = useState({});
  const [workspaces, setWorkspaces] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [alertState, setAlertState] = useState(null);

  useEffect(() => {
    if (!user || !user.user_id || !user.workspace_id || !user.slack_id) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [user]);

  useEffect(() => {
    getWorkspaces().then((res) => {
      setWorkspaces(res.data);
    });
  }, []);

  const submitForm = () => {
    createUser(user)
      .then((res) => {
        setAlertState("success");
      })
      .catch((err) => {
        setAlertState("error");
      });
  };

  const handleChange = (e, elem) => {
    const propName = e.target.id || elem.props.name;
    setUser({ ...user, [propName]: e.target.value });
  };

  return (
    <PageContainer>
      <h1>Add New User</h1>
      <div style={{ bottom: 0, position: "relative" }}>
        <FormControl className={classes.formControl}>
          <TextField
            id="user_id"
            label="Identifiant (beta)"
            type="number"
            defaultValue=""
            onChange={handleChange}
            variant="standard"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="name"
            label="Name"
            type="text"
            defaultValue=""
            onChange={handleChange}
            autoComplete="off"
            variant="standard"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="workspace-select">Workspace</InputLabel>
          <Select
            labelId="workspace-select"
            id="workspace_id"
            onChange={handleChange}
            defaultValue=""
            variant="standard"
          >
            {workspaces.map((workspace) => (
              <MenuItem
                value={workspace.workspace_id}
                key={workspace.workspace_id}
                name="workspace_id"
              >
                {workspace.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="slack_id"
            label="Slack ID"
            type="text"
            defaultValue=""
            onChange={handleChange}
            autoComplete="off"
            variant="standard"
          />
        </FormControl>
        <Button
          disabled={!isFormValid}
          onClick={submitForm}
          variant="contained"
          color="primary"
          style={{ bottom: 7, position: "absolute", marginLeft: 10 }}
        >
          Ajouter
        </Button>
      </div>

      <div style={{ marginTop: 30 }}>
        <p>To find a user's slack id :</p>
        <img
          src="/find-user-slack-id.png"
          height="500px"
          alt="Find a user's slack id"
        ></img>
      </div>

      <AlertSnackbar
        state={alertState}
        handleClose={() => setAlertState(null)}
      />
    </PageContainer>
  );
}

export default AddUser;
