import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getUsers } from "../api";
import PageContainer from "../components/PageContainer";
import CircularProgress from "@mui/material/CircularProgress";

function Users() {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    getUsers().then((res) => {
      setUsers(res.data);
    });
  }, []);

  return (
    <PageContainer>
      <h1>List of users</h1>
      {!users ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div>
            <ul>
              {users.map((user) => (
                <li key={user.user_id}>
                  <Link to={`/user/${user.user_id}`}>{user.name}</Link>
                </li>
              ))}
              <li>
                <Link to="/user/new">Add New</Link>
              </li>
            </ul>
          </div>
        </>
      )}
    </PageContainer>
  );
}

export default Users;
